// card
.mat-mdc-card {
  margin-bottom: 24px;
  border-radius: $border-radius;
}
.mat-mdc-card-header {
  padding: 24px 24px 0;
}

.mat-mdc-card-content {
  padding: 0 24px;
}

.mat-mdc-card {
  background-color: $cardbg;
}

.cardWithShadow {
  box-shadow: $cardshadow;
}

.mat-mdc-card-title {
  line-height: 1.6rem;
  font-size: 18px;
  font-weight: 500;
  color: $textPrimary;
}

.mat-mdc-card-subtitle {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  color: $textPrimary;
}

.mdc-card__actions {
  padding: 24px;
}

.theme-card.mat-mdc-card {
  .mat-mdc-card-header {
    padding: 16px 24px;
  }
  .mat-mdc-card-content {
    padding: 24px;
  }
}

.card-hover {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    scale: 1.01;
    transition: all 0.1s ease-in 0s;
  }
}

// chip
html .mat-mdc-chip {
  height: 24px;
  font-size: 14px;
  background-color: $light;
}

.cardBorder {
  .mdc-card {
    box-shadow: none !important;
    border: 1px solid $borderColor !important;
    &.shadow-none {
      border: 0 !important;
    }
  }
}
