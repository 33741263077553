@font-face {
  font-family: 'font-agro';
  src: url('../font/font-agro.eot?51889553');
  src: url('../font/font-agro.eot?51889553#iefix') format('embedded-opentype'),
       url('../font/font-agro.woff2?51889553') format('woff2'),
       url('../font/font-agro.woff?51889553') format('woff'),
       url('../font/font-agro.ttf?51889553') format('truetype'),
       url('../font/font-agro.svg?51889553#font-agro') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'font-agro';
    src: url('../font/font-agro.svg?51889553#font-agro') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "font-agro";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-spray-can-sparkles:before { content: '\e800'; } /* '' */
.icon-spray-can:before { content: '\e801'; } /* '' */
.icon-seedling:before { content: '\e802'; } /* '' */
.icon-calculator:before { content: '\e803'; } /* '' */
.icon-tractor:before { content: '\e804'; } /* '' */
.icon-syringe-1:before { content: '\e805'; } /* '' */
.icon-truck-fast:before { content: '\e806'; } /* '' */
.icon-thumbs-up-solid:before { content: '\e807'; } /* '' */
.icon-thumbs-down-solid:before { content: '\e808'; } /* '' */
.icon-thumbs-up:before { content: '\e809'; } /* '' */
.icon-thumbs-down:before { content: '\e80a'; } /* '' */
.icon-lemon-solid:before { content: '\e80b'; } /* '' */
.icon-lemon:before { content: '\e80c'; } /* '' */
.icon-truck:before { content: '\e80d'; } /* '' */
.icon-truck-ramp-box:before { content: '\e80e'; } /* '' */
.icon-truck-plane:before { content: '\e80f'; } /* '' */
.icon-truck-pickup:before { content: '\e810'; } /* '' */
.icon-truck-moving:before { content: '\e811'; } /* '' */
.icon-mountain-city:before { content: '\e812'; } /* '' */
.icon-city:before { content: '\e813'; } /* '' */
.icon-arrow-right-to-city:before { content: '\e814'; } /* '' */
.icon-tree-city:before { content: '\e815'; } /* '' */
.icon-money-check-dollar:before { content: '\e816'; } /* '' */
.icon-money-bills:before { content: '\e817'; } /* '' */
.icon-money-bill:before { content: '\e818'; } /* '' */
.icon-money-bill-wheat:before { content: '\e819'; } /* '' */
.icon-money-check:before { content: '\e81a'; } /* '' */
.icon-handshake:before { content: '\e81b'; } /* '' */
.icon-handshake-slash:before { content: '\e81c'; } /* '' */
.icon-handshake-simple:before { content: '\e81d'; } /* '' */
.icon-handshake-simple-slash:before { content: '\e81e'; } /* '' */
.icon-handshake-angle:before { content: '\e81f'; } /* '' */
.icon-bug:before { content: '\e820'; } /* '' */
.icon-bug-slash:before { content: '\e821'; } /* '' */
.icon-bugs:before { content: '\e822'; } /* '' */
.icon-square-caret-right:before { content: '\e823'; } /* '' */
.icon-square-caret-left:before { content: '\e824'; } /* '' */
.icon-square-caret-down:before { content: '\e825'; } /* '' */
.icon-vr-cardboard:before { content: '\e826'; } /* '' */
.icon-square-caret-up:before { content: '\e827'; } /* '' */
.icon-car-side:before { content: '\e828'; } /* '' */
.icon-truck-front:before { content: '\e829'; } /* '' */
.icon-credit-card-solid:before { content: '\e82a'; } /* '' */
.icon-credit-card:before { content: '\e82b'; } /* '' */
.icon-laptop:before { content: '\e82c'; } /* '' */
.icon-laptop-medical:before { content: '\e82d'; } /* '' */
.icon-laptop-file:before { content: '\e82e'; } /* '' */
.icon-laptop-code:before { content: '\e82f'; } /* '' */
.icon-house-laptop:before { content: '\e830'; } /* '' */
.icon-desktop:before { content: '\e831'; } /* '' */
.icon-leaf:before { content: '\e832'; } /* '' */
.icon-canadian-maple-leaf:before { content: '\e833'; } /* '' */
.icon-shower-solid:before { content: '\e834'; } /* '' */
.icon-cloud-showers-water:before { content: '\e835'; } /* '' */
.icon-cloud-showers-heavy:before { content: '\e836'; } /* '' */
.icon-file-pdf-solid:before { content: '\e837'; } /* '' */
.icon-file-pdf:before { content: '\e838'; } /* '' */
.icon-file-excel-solid:before { content: '\e839'; } /* '' */
.icon-file-excel-1:before { content: '\e83a'; } /* '' */
.icon-file-word:before { content: '\e83b'; } /* '' */
.icon-wordpress:before { content: '\e83c'; } /* '' */
.icon-wordpress-simple:before { content: '\e83d'; } /* '' */
.icon-file-word-solid:before { content: '\e83e'; } /* '' */
.icon-hammer:before { content: '\e83f'; } /* '' */
.icon-user-plus-solid:before { content: '\e840'; } /* '' */
.icon-user-injured:before { content: '\e841'; } /* '' */
.icon-user-group:before { content: '\e842'; } /* '' */
.icon-user-graduate:before { content: '\e843'; } /* '' */
.icon-user-gear:before { content: '\e844'; } /* '' */
.icon-user-doctor:before { content: '\e845'; } /* '' */
.icon-user-clock:before { content: '\e846'; } /* '' */
.icon-user-check:before { content: '\e847'; } /* '' */
.icon-user-astronaut:before { content: '\e848'; } /* '' */
.icon-users:before { content: '\e849'; } /* '' */
.icon-users-viewfinder:before { content: '\e84a'; } /* '' */
.icon-users-slash:before { content: '\e84b'; } /* '' */
.icon-users-rectangle:before { content: '\e84c'; } /* '' */
.icon-users-rays:before { content: '\e84d'; } /* '' */
.icon-users-line:before { content: '\e84e'; } /* '' */
.icon-users-gear:before { content: '\e84f'; } /* '' */
.icon-users-between-lines:before { content: '\e850'; } /* '' */
.icon-user:before { content: '\e851'; } /* '' */
.icon-user-xmark:before { content: '\e852'; } /* '' */
.icon-user-tie:before { content: '\e853'; } /* '' */
.icon-user-tag:before { content: '\e854'; } /* '' */
.icon-user-slash:before { content: '\e855'; } /* '' */
.icon-user-shield:before { content: '\e856'; } /* '' */
.icon-user-secret:before { content: '\e857'; } /* '' */
.icon-user-plus:before { content: '\e858'; } /* '' */
.icon-user-pen:before { content: '\e859'; } /* '' */
.icon-user-nurse:before { content: '\e85a'; } /* '' */
.icon-user-ninja:before { content: '\e85b'; } /* '' */
.icon-user-minus:before { content: '\e85c'; } /* '' */
.icon-user-lock:before { content: '\e85d'; } /* '' */
.icon-user-large:before { content: '\e85e'; } /* '' */
.icon-user-large-slash:before { content: '\e85f'; } /* '' */
.icon-upload:before { content: '\e860'; } /* '' */
.icon-delete-left:before { content: '\e861'; } /* '' */
.icon-van-shuttle:before { content: '\e862'; } /* '' */
.icon-trash:before { content: '\e863'; } /* '' */
.icon-trash-can-solid:before { content: '\e864'; } /* '' */
.icon-trash-can-arrow-up:before { content: '\e865'; } /* '' */
.icon-trash-arrow-up:before { content: '\e866'; } /* '' */
.icon-trash-can:before { content: '\e867'; } /* '' */
.icon-snowplow:before { content: '\e868'; } /* '' */
.icon-screwdriver-wrench:before { content: '\e869'; } /* '' */
.icon-screwdriver:before { content: '\e86a'; } /* '' */
.icon-filter-circle-xmark-solid:before { content: '\e86b'; } /* '' */
.icon-filter-circle-dollar-solid:before { content: '\e86c'; } /* '' */
.icon-filter:before { content: '\e86d'; } /* '' */
.icon-file-pen:before { content: '\e86e'; } /* '' */
.icon-file-audio-solid:before { content: '\e86f'; } /* '' */
.icon-file-circle-check-solid:before { content: '\e870'; } /* '' */
.icon-file-circle-exclamation:before { content: '\e871'; } /* '' */
.icon-file-circle-minus-solid:before { content: '\e872'; } /* '' */
.icon-file-circle-plus-solid:before { content: '\e873'; } /* '' */
.icon-file-circle-question-solid:before { content: '\e874'; } /* '' */
.icon-file-circle-xmark-solid:before { content: '\e875'; } /* '' */
.icon-file-code-solid:before { content: '\e876'; } /* '' */
.icon-file-contract:before { content: '\e877'; } /* '' */
.icon-file-csv:before { content: '\e878'; } /* '' */
.icon-file-excel-solid-1:before { content: '\e879'; } /* '' */
.icon-file-export:before { content: '\e87a'; } /* '' */
.icon-file-image-solid:before { content: '\e87b'; } /* '' */
.icon-file-import:before { content: '\e87c'; } /* '' */
.icon-file-invoice:before { content: '\e87d'; } /* '' */
.icon-file-invoice-dollar:before { content: '\e87e'; } /* '' */
.icon-file-lines-solid:before { content: '\e87f'; } /* '' */
.icon-file-medical:before { content: '\e880'; } /* '' */
.icon-file-pen-solid:before { content: '\e882'; } /* '' */
.icon-file-powerpoint-solid:before { content: '\e883'; } /* '' */
.icon-file-prescription:before { content: '\e884'; } /* '' */
.icon-file-shield:before { content: '\e885'; } /* '' */
.icon-file-signature:before { content: '\e886'; } /* '' */
.icon-file-video-solid:before { content: '\e887'; } /* '' */
.icon-file-waveform:before { content: '\e888'; } /* '' */
.icon-file-zipper-solid:before { content: '\e88a'; } /* '' */
.icon-file-solid:before { content: '\e88b'; } /* '' */
.icon-file-arrow-down:before { content: '\e88c'; } /* '' */
.icon-file-arrow-up:before { content: '\e88d'; } /* '' */
.icon-file-lines:before { content: '\e88e'; } /* '' */
.icon-file-powerpoint:before { content: '\e890'; } /* '' */
.icon-file-video:before { content: '\e891'; } /* '' */
.icon-file-zipper:before { content: '\e893'; } /* '' */
.icon-file:before { content: '\e894'; } /* '' */
.icon-file-audio:before { content: '\e895'; } /* '' */
.icon-file-code:before { content: '\e896'; } /* '' */
.icon-file-excel:before { content: '\e897'; } /* '' */
.icon-file-image:before { content: '\e898'; } /* '' */
.icon-fonticons-fi:before { content: '\e899'; } /* '' */
.icon-fort-awesome:before { content: '\e89a'; } /* '' */
.icon-fort-awesome-alt:before { content: '\e89b'; } /* '' */
.icon-flutter:before { content: '\e89c'; } /* '' */
.icon-font-awesome-solid:before { content: '\e89d'; } /* '' */
.icon-fonticons:before { content: '\e89e'; } /* '' */
.icon-map:before { content: '\e89f'; } /* '' */
.icon-map-solid:before { content: '\e8a0'; } /* '' */
.icon-map-pin:before { content: '\e8a1'; } /* '' */
.icon-map-location:before { content: '\e8a2'; } /* '' */
.icon-map-location-dot:before { content: '\e8a3'; } /* '' */
.icon-folder-minus:before { content: '\e8a4'; } /* '' */
.icon-folder-solid:before { content: '\e8a5'; } /* '' */
.icon-folder-tree:before { content: '\e8a6'; } /* '' */
.icon-folder-plus:before { content: '\e8a7'; } /* '' */
.icon-folder-open:before { content: '\e8a8'; } /* '' */
.icon-folder-closed:before { content: '\e8a9'; } /* '' */
.icon-folder-closed-solid:before { content: '\e8aa'; } /* '' */
.icon-font-awesome-solid-solid:before { content: '\e8ab'; } /* '' */
.icon-floppy-disk-solid:before { content: '\e8ac'; } /* '' */
.icon-florin-sign:before { content: '\e8ad'; } /* '' */
.icon-folder-solid-1:before { content: '\e8ae'; } /* '' */
.icon-folder-closed-solid-solid:before { content: '\e8af'; } /* '' */
.icon-folder-minus-solid:before { content: '\e8b0'; } /* '' */
.icon-folder-open-solid-solid:before { content: '\e8b1'; } /* '' */
.icon-folder-plus-solid:before { content: '\e8b2'; } /* '' */
.icon-font:before { content: '\e8b4'; } /* '' */
.icon-font-awesome:before { content: '\e8b5'; } /* '' */
.icon-floppy-disk:before { content: '\e8b6'; } /* '' */
.icon-folder:before { content: '\e8b7'; } /* '' */
.icon-folder-open-solid:before { content: '\e8b9'; } /* '' */
.icon-address-card-solid:before { content: '\e8ba'; } /* '' */
.icon-0:before { content: '\e8bb'; } /* '' */
.icon-1:before { content: '\e8bc'; } /* '' */
.icon-2:before { content: '\e8bd'; } /* '' */
.icon-3:before { content: '\e8be'; } /* '' */
.icon-4:before { content: '\e8bf'; } /* '' */
.icon-5:before { content: '\e8c0'; } /* '' */
.icon-6:before { content: '\e8c1'; } /* '' */
.icon-7:before { content: '\e8c2'; } /* '' */
.icon-8:before { content: '\e8c3'; } /* '' */
.icon-9:before { content: '\e8c4'; } /* '' */
.icon-a:before { content: '\e8c5'; } /* '' */
.icon-address-book-solid:before { content: '\e8c6'; } /* '' */
.icon-bell:before { content: '\e8c7'; } /* '' */
.icon-address-book:before { content: '\e8c8'; } /* '' */
.icon-address-card:before { content: '\e8c9'; } /* '' */
.icon-bell-slash-solid:before { content: '\e8ca'; } /* '' */
.icon-bell-solid:before { content: '\e8cb'; } /* '' */
.icon-bell-concierge:before { content: '\e8cc'; } /* '' */
.icon-bell-slash:before { content: '\e8cd'; } /* '' */
.icon-circle-user:before { content: '\e8ce'; } /* '' */
.icon-circle-user-solid-solid:before { content: '\e8cf'; } /* '' */
.icon-circle-check:before { content: '\e8d0'; } /* '' */
.icon-circle:before { content: '\e8d1'; } /* '' */
.icon-circle-xmark:before { content: '\e8d2'; } /* '' */
.icon-circle-up:before { content: '\e8d4'; } /* '' */
.icon-circle-stop:before { content: '\e8d5'; } /* '' */
.icon-circle-right:before { content: '\e8d6'; } /* '' */
.icon-circle-question:before { content: '\e8d7'; } /* '' */
.icon-circle-play:before { content: '\e8d8'; } /* '' */
.icon-circle-pause:before { content: '\e8d9'; } /* '' */
.icon-circle-left:before { content: '\e8da'; } /* '' */
.icon-circle-down:before { content: '\e8db'; } /* '' */
.icon-circle-dot:before { content: '\e8dc'; } /* '' */
.icon-circle-arrow-down:before { content: '\e8dd'; } /* '' */
.icon-circle-solid:before { content: '\e8de'; } /* '' */
.icon-circle-xmark-solid:before { content: '\e8df'; } /* '' */
.icon-circle-user-solid:before { content: '\e8e0'; } /* '' */
.icon-circle-up-solid:before { content: '\e8e1'; } /* '' */
.icon-circle-stop-solid:before { content: '\e8e2'; } /* '' */
.icon-circle-right-solid:before { content: '\e8e3'; } /* '' */
.icon-circle-radiation:before { content: '\e8e4'; } /* '' */
.icon-circle-question-solid:before { content: '\e8e5'; } /* '' */
.icon-circle-plus:before { content: '\e8e6'; } /* '' */
.icon-circle-play-solid:before { content: '\e8e7'; } /* '' */
.icon-circle-pause-solid:before { content: '\e8e8'; } /* '' */
.icon-circle-notch:before { content: '\e8e9'; } /* '' */
.icon-circle-nodes:before { content: '\e8ea'; } /* '' */
.icon-circle-minus:before { content: '\e8eb'; } /* '' */
.icon-circle-left-solid:before { content: '\e8ec'; } /* '' */
.icon-circle-info:before { content: '\e8ed'; } /* '' */
.icon-circle-half-stroke:before { content: '\e8ee'; } /* '' */
.icon-circle-h:before { content: '\e8ef'; } /* '' */
.icon-circle-exclamation:before { content: '\e8f0'; } /* '' */
.icon-circle-down-solid:before { content: '\e8f1'; } /* '' */
.icon-circle-dot-solid:before { content: '\e8f2'; } /* '' */
.icon-circle-dollar-to-slot:before { content: '\e8f3'; } /* '' */
.icon-circle-chevron-up:before { content: '\e8f4'; } /* '' */
.icon-circle-chevron-right:before { content: '\e8f5'; } /* '' */
.icon-circle-chevron-left:before { content: '\e8f6'; } /* '' */
.icon-circle-chevron-down:before { content: '\e8f7'; } /* '' */
.icon-circle-check-solid:before { content: '\e8f8'; } /* '' */
.icon-circle-arrow-up:before { content: '\e8f9'; } /* '' */
.icon-circle-arrow-right:before { content: '\e8fa'; } /* '' */
.icon-circle-arrow-left:before { content: '\e8fb'; } /* '' */
.icon-file-circle-check:before { content: '\e8fc'; } /* '' */
.icon-filter-circle-xmark:before { content: '\e8fd'; } /* '' */
.icon-filter-circle-dollar:before { content: '\e8fe'; } /* '' */
.icon-file-circle-xmark:before { content: '\e8ff'; } /* '' */
.icon-file-circle-question:before { content: '\e900'; } /* '' */
.icon-file-circle-plus:before { content: '\e901'; } /* '' */
.icon-file-circle-minus:before { content: '\e902'; } /* '' */
.icon-file-circle-exclamation-solid:before { content: '\e903'; } /* '' */
.icon-youtube:before { content: '\e904'; } /* '' */
.icon-square-youtube:before { content: '\e905'; } /* '' */
.icon-instagram:before { content: '\e906'; } /* '' */
.icon-square-instagram:before { content: '\e907'; } /* '' */
.icon-instalod:before { content: '\e908'; } /* '' */
.icon-linkedin-in:before { content: '\e909'; } /* '' */
.icon-linkedin:before { content: '\e90a'; } /* '' */
.icon-facebook-f:before { content: '\e90b'; } /* '' */
.icon-square-facebook:before { content: '\e90c'; } /* '' */
.icon-facebook:before { content: '\e90d'; } /* '' */
.icon-facebook-messenger:before { content: '\e90e'; } /* '' */
.icon-print:before { content: '\e90f'; } /* '' */
.icon-shoe-prints:before { content: '\e910'; } /* '' */
.icon-nutrition-solid:before { content: '\e912'; } /* '' */
.icon-nutrition:before { content: '\e913'; } /* '' */
.icon-grocery:before { content: '\e914'; } /* '' */
.icon-household_supplies:before { content: '\e915'; } /* '' */
.icon-nest_eco_leaf:before { content: '\e916'; } /* '' */
.icon-shower:before { content: '\e918'; } /* '' */
.icon-sprinkler:before { content: '\e919'; } /* '' */
.icon-weather_snowy:before { content: '\e91a'; } /* '' */
.icon-local_shipping:before { content: '\e91b'; } /* '' */
.icon-psychiatry:before { content: '\e91c'; } /* '' */
.icon-airport_shuttle:before { content: '\e91d'; } /* '' */
.icon-auto_towing:before { content: '\e91e'; } /* '' */
.icon-forest:before { content: '\e91f'; } /* '' */
.icon-gas-pump-solid:before { content: '\e922'; } /* '' */
.icon-grass:before { content: '\ea2e'; } /* '' */
.icon-grass_one:before { content: '\ea34'; } /* '' */
.icon-grass_two:before { content: '\ea35'; } /* '' */
.icon-grass_three:before { content: '\ea36'; } /* '' */
.icon-bug_report:before { content: '\ea37'; } /* '' */
.icon-injection-check:before { content: '\ea38'; } /* '' */
.icon-syringe:before { content: '\ea39'; } /* '' */
.icon-injection:before { content: '\ea3b'; } /* '' */
.icon-cherry:before { content: '\ea3c'; } /* '' */
.icon-cherry-solid:before { content: '\ea3d'; } /* '' */
.icon-lemon-part:before { content: '\ea3e'; } /* '' */
