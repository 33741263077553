.breadcrumb-item {
  &:first-child {
    &:after {
      content: ">";
      margin-left: 15px;
    }
  }
  &.active {
    margin-left: 20px;
  }
  a {
    text-decoration: none;
    color: $textPrimary;
    font-size: 14px;
  }
}
