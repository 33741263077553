$mat-primary: (
  100: #ebf9fa,
  500: #3C4B64,
  700: #282332,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    700: white,
  ),
);
$mat-secondary: (
  100: #fff5f2,
  500: #F9C349,
  700: #f48b6c,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);
$mat-warn: (
  100: FDEDE8,
  500: #e91e63,
  700: #e91e63,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-sucess: (
  100: #2be79c,
  500: #008748,
  700: #008852,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$theme-primary: mat.define-palette($mat-primary, 500);
$theme-accent: mat.define-palette($mat-secondary, 500);
$theme-warn: mat.define-palette($mat-warn, 500);
$theme-sucess: mat.define-palette($mat-sucess, 500);

$agrocontroletheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      sucess: $theme-sucess
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Poppins",
      ),
  )
);

$darkagrocontroletheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      sucess: $theme-sucess
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Poppins",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
         $headline-6: mat.define-typography-level(18px, 26px, 500),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

.bg-primary,
.topbar {
  background: mat.get-color-from-palette($theme-primary, 500) !important;
}

.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}

.activeMenu {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}

.topbar .mat-mdc-icon-button,
.topbar .mat-mdc-button {
  color: $white !important;
}

.mat-mdc-outlined-button {
  &.mat-primary{
    border-color: mat.get-color-from-palette($theme-primary, 500) !important;
  }
  &.mat-accent{
    border-color: mat.get-color-from-palette($theme-accent, 500) !important;
  }
  &.mat-warn{
    border-color: mat.get-color-from-palette($theme-warn, 500) !important;
  }  
  &.mat-sucess {
    border-color: mat.get-color-from-palette($theme-sucess, 500) !important;
  }
}
